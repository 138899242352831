import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import BlockUi from 'react-block-ui';
import has from 'lodash/has';
import { SignUpLayout } from '../../../components/Layout';
import api from '../../../utils/api';
import handleFormError from '../../../utils/handleFormError';
import FieldError from '../../../components/Error/FieldError';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';

export default ({ location }) => {
  const [formData, setFormData] = useState({
    isLoading: false,
    errors: [],
    address: has(location, 'state.address') ? location.state.address : '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    consent: false,
  });

  const disableSubmit = () => {
    return (
      formData.isLoading ||
      !formData.email ||
      !formData.firstName ||
      !formData.lastName ||
      !formData.address ||
      !formData.consent
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormData({
      ...formData,
      isLoading: true,
    });

    try {
      await api.post('/registration/properties', {
        address: formData.address,
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        message: formData.message,
        phoneNumber: formData.phoneNumber,
      });

      setFormData({
        ...formData,
        isLoading: false,
        errors: [],
      });

      trackCustomEvent({
        category: 'signUp',
        action: 'valid new property request form',
        label: formData.address,
      });

      navigate('/inscription/nouvelle-adresse/merci/', {
        replace: true,
      });
    } catch (error) {
      handleFormError(error);
      setFormData({ ...formData, isLoading: false, errors: [] });
    }
  };

  const metaTitle = `Inscrivez votre résidence sur Alacaza`;
  const metaDescription = `Inscrivez votre résidence sur Alacaza et échangez avec vos voisins !`;

  const [fromMobileApp, setFromMobileApp] = useState(false);
  const [platform, setPlatform] = useState(null);

  useEffect(() => {
    const validParams = queryString.parse(location.search);

    if (has(validParams, 'platform')) {
      setPlatform(validParams.platform);
    }
  }, [location.search]);

  useEffect(() => {
    setFromMobileApp(['ANDROID', 'IOS'].includes(platform));
  }, [platform]);

  return (
    <SignUpLayout fromMobileApp={fromMobileApp}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />

        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:url"
          content={`${process.env.GATSBY_WEBSITE_URL}/inscription/nouvelle-address/`}
        />

        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
      </Helmet>

      <BlockUi tag="div" blocking={formData.isLoading} renderChildren={true}>
        <section className="container step new-address mt-10 pl-5-md pr-5-md">
          <div className="row">
            <div className="col-12">
              <div className="roboto bold font-size-lg font-size-md-sm">
                Nous ne sommes malheureusement pas encore partout{' '}
                <span role="img" aria-label="Hoho" className="font-size-lg">
                  &#128540;
                </span>{' '}
                mais nous allons très vite arriver !
              </div>
              <div className="mt-5 font-size-md font-size-sm-sm">
                Pour profiter et faire profiter vos voisins d'Alacaza en
                priorité, complétez les informations ci-dessous. Nous vous
                contacterons dès notre arrivée dans votre quartier !
              </div>
            </div>
          </div>

          <div className="row">
            <form onSubmit={handleSubmit}>
              <div className="col-12 pt-5">
                <label htmlFor="email">Votre adresse de résidence *</label>
                <FieldError fieldName="address" errors={formData.errors} />
                <input
                  type="text"
                  name="address"
                  id="address"
                  className="text-input"
                  minLength={2}
                  maxLength={100}
                  placeholder="Adresse postale"
                  autocomplete="shipping street-address"
                  required
                  value={formData.address}
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                />
              </div>

              <div className="col-12">
                <div className="inline size-50 pr-1">
                  <label htmlFor="firstName">Prénom *</label>
                  <FieldError fieldName="firstName" errors={formData.errors} />
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    className="text-input"
                    minLength={2}
                    maxLength={100}
                    placeholder="Prénom"
                    autoComplete="given-name"
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, firstName: e.target.value })
                    }
                  />
                </div>
                <div className="inline size-50 pl-1">
                  <label htmlFor="lastName">Nom *</label>
                  <FieldError fieldName="lastName" errors={formData.errors} />
                  <input
                    type="text"
                    name="lname"
                    id="lname"
                    className="text-input"
                    minLength={2}
                    maxLength={100}
                    placeholder="Nom"
                    autoComplete="family-name"
                    required
                    onChange={(e) =>
                      setFormData({ ...formData, lastName: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="col-12">
                <label htmlFor="email">Email *</label>
                <FieldError fieldName="email" errors={formData.errors} />
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="text-input left-icon"
                  minLength={6}
                  maxLength={100}
                  placeholder="Email"
                  autoComplete="email"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>

              <div className="col-12">
                <label htmlFor="email">Téléphone</label>
                <FieldError fieldName="phoneNumber" errors={formData.errors} />
                <PhoneInput
                  inputProps={{
                    name: 'phoneNumber',
                  }}
                  country={'fr'}
                  inputClass="text-input"
                  inputStyle={{ width: '100%' }}
                  value={formData.phoneNumber}
                  onChange={(phoneNumber) =>
                    setFormData({ ...formData, phoneNumber: phoneNumber })
                  }
                  countryCodeEditable={false}
                />
              </div>

              <div className="step-footer ml-auto mr-auto text-align-center">
                <div className="col-12 pt-10">
                  <div className="pt-2 pb-5 pl-2-md pr-2-md font-size-sm text-color-purple-grey text-align-left font-size-xs-md">
                    <input
                      type="checkbox"
                      name="consent"
                      className="toggle-input mr-2"
                      onChange={(e) =>
                        setFormData({ ...formData, consent: e.target.checked })
                      }
                    />
                    J’accepte les &nbsp;
                    <Link to="/cgu/" className="text-underline">
                      conditions générales d’utilisation{' '}
                    </Link>
                    &nbsp;d’Alacaza.
                  </div>

                  <div className="">
                    <Link
                      to={`/inscription/${
                        platform ? '?&platform=' + platform : ''
                      }`}
                      className="btn secondary mr-2"
                    >
                      Annuler
                    </Link>
                    <button
                      className={
                        'btn ml-2 ' +
                        (formData.isLoading === true || disableSubmit()
                          ? 'disabled'
                          : '')
                      }
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </BlockUi>
    </SignUpLayout>
  );
};
